import React from "react";
import mock01 from '../assets/images/mock01.png';
import mock02 from '../assets/images/mock02.png';
import mock03 from '../assets/images/mock03.png';
import mock04 from '../assets/images/mock04.png';
import mock05 from '../assets/images/mock05.png';
import mock06 from '../assets/images/mock06.png';
import mock07 from '../assets/images/mock07.png';
import mock08 from '../assets/images/mock08.png';
import mock09 from '../assets/images/mock09.png';
import mock10 from '../assets/images/mock10.png';
import aws_dva from '../assets/images/aws-certified-developer-associate-2.png';
import unb from '../assets/images/unb-cs.png';
import googleCloud from '../assets/images/google-cloud.png';
import '../assets/styles/Project.scss';
type CourseType = {
    img: string;
    title: string;
    description: string;
    issuedBy: string;
    issuedAt: string;
    url: string;
}
const certificates: CourseType[] = [
    {
        img: aws_dva,
        title: "AWS Certified Developer – Associate",
        description: "",
        issuedBy: "Amazon Web Services Training and Certification",
        issuedAt: "December 30, 2024",
        url: "https://www.credly.com/badges/a87368a5-cc5d-46ec-bf54-0f5800ccc42c/public_url"
    },

]

const courses: CourseType[] = [
    {
        img: unb,
        title: "OpenMP Bootcamp",
        description: "",
        issuedBy: "University of New Brunswick",
        issuedAt: "February 2, 2023",
        url: "https://drive.google.com/file/d/18yl_6mmXMWqnVtFls5XpKtnbPZAEZzA_/view?usp=share_link"
    },
    {
        img: googleCloud,
        title: "Data Engineering, Big Data, and Machine Learning on GCP",
        description: "",
        issuedBy: "Google Cloud Training, through Coursera",
        issuedAt: "August 27, 2021",
        url: "https://www.coursera.org/account/accomplishments/specialization/certificate/MU54D5GCJUP4"
    },
    {
        img: googleCloud,
        title: "Google Cloud Big Data and Machine Learning Fundamentals",
        description: "",
        issuedBy: "Google Cloud Training, through Coursera",
        issuedAt: "August 09, 2021",
        url: "https://www.coursera.org/account/accomplishments/certificate/UWGM93D5R94S"
    },
    {
        img: googleCloud,
        title: "Modernizing Data Lakes and Data Warehouses with Google Cloud",
        description: "",
        issuedBy: "Google Cloud Training, through Coursera",
        issuedAt: "August 14, 2021",
        url: "https://www.coursera.org/account/accomplishments/certificate/E2QLSDF45FBF"
    },
    {
        img: googleCloud,
        title: "Building Batch Data Pipelines on Google Cloud",
        description: "",
        issuedBy: "Google Cloud Training, through Coursera",
        issuedAt: "August 19, 2021",
        url: "https://www.coursera.org/account/accomplishments/certificate/YDFTQP8YXQ6C"
    },
    {
        img: googleCloud,
        title: "Building Resilient Streaming Analytics Systems on Google Cloud",
        description: "",
        issuedBy: "Google Cloud Training, through Coursera",
        issuedAt: "August 26, 2021",
        url: "https://www.coursera.org/account/accomplishments/certificate/3QGJ9E7SSQBV"
    },
    {
        img: googleCloud,
        title: "Smart Analytics, Machine Learning, and AI on Google Cloud",
        description: "",
        issuedBy: "Google Cloud Training, through Coursera",
        issuedAt: "August 27, 2021",
        url: "https://www.coursera.org/account/accomplishments/certificate/J28DVQMT83FT"
    },
    {
        img: googleCloud,
        title: "Exploring and Preparing your Data with BigQuery",
        description: "",
        issuedBy: "Google Cloud Training, through Coursera",
        issuedAt: "August 03, 2021",
        url: "https://www.coursera.org/account/accomplishments/certificate/RS9NF5M8VFEH"
    },
    {
        img: googleCloud,
        title: "Serverless Data Processing with Dataflow: Foundations",
        description: "",
        issuedBy: "Google Cloud Training, through Coursera",
        issuedAt: "August 31, 2021",
        url: "https://www.coursera.org/account/accomplishments/certificate/PADKKR9DNVYW"
    },
    {
        img: googleCloud,
        title: "Google Cloud Fundamentals: Core Infrastructure",
        description: "",
        issuedBy: "Google Cloud Training, through Coursera",
        issuedAt: "July 28, 2021",
        url: "https://www.coursera.org/account/accomplishments/certificate/KZDZ7GSGK5CJ"
    },
]

function Project() {
    return (<>
        <div className="certificate-container" id="certificates">
            <h1>Certification</h1>
            <div className="certificate-grid">
                {certificates.map((cert, index) => (
                    <div className="certifiate-card" key={index}>
                        <div className="certificate-head">
                            <div className="certificate-img">
                                <img src={cert.img} alt="Alt Title" />
                            </div>
                            <div className="certificate-details">
                                <h2>{cert.title}</h2>
                                <p>{cert.description}</p>
                                <p><strong>Issued By:</strong> {cert.issuedBy}</p>
                                <p><strong>Issued On:</strong> {cert.issuedAt}</p>
                                <a href={cert.url} target="_blank" rel="noreferrer">View Certificate</a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div >
        <div className="certificate-container" id="courses">
            <h1>Courses</h1>
            <div className="certificate-grid">
                {courses.map((cert, index) => (
                    <div className="certifiate-card" key={index}>
                        <div className="certificate-head">
                            <div className="certificate-img">
                                <img src={cert.img} alt="Alt Title" />
                            </div>
                            <div className="certificate-details">
                                <h2>{cert.title}</h2>
                                <p>{cert.description}</p>
                                <p><strong>Issued By:</strong> {cert.issuedBy}</p>
                                <p><strong>Issued On:</strong> {cert.issuedAt}</p>
                                <a href={cert.url} target="_blank" rel="noreferrer">View Certificate</a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div >
        {/* <div className="projects-container" id="projects">
            <h1>Certifications and Courses</h1>
            <div className="projects-grid">


                <div className="project">
                    <a href="https://www.filmate.club/" target="_blank" rel="noreferrer"><img src={mock10} className="zoom" alt="thumbnail" width="100%" /></a>
                    <a href="https://www.filmate.club/" target="_blank" rel="noreferrer"><h2>Filmate AI</h2></a>
                    <p>Developed movie finder app with semantic search and sentiment analysis using OpenAI GPT-3.5 Turbo, Qdrant, React, and Flask.</p>
                </div>
                <div className="project">
                    <a href="https://yujisatojr.itch.io/highspeedchase" target="_blank" rel="noreferrer"><img src={mock09} className="zoom" alt="thumbnail" width="100%" /></a>
                    <a href="https://yujisatojr.itch.io/highspeedchase" target="_blank" rel="noreferrer"><h2>High Speed Chase</h2></a>
                    <p>Designed, developed, and launched a 3D multiplayer racing game with C# and Unity. This is available on Itch.io for gamers worldwide to enjoy.</p>
                </div>
                <div className="project">
                    <a href="https://yujisatojr.itch.io/spacecraft" target="_blank" rel="noreferrer"><img src={mock08} className="zoom" alt="thumbnail" width="100%" /></a>
                    <a href="https://yujisatojr.itch.io/spacecraft" target="_blank" rel="noreferrer"><h2>Astro Raiders</h2></a>
                    <p>Developed and released a 2D shooting game with C# and Unity. This project is hosted on the Itch.io public marketplace.</p>
                </div>
                <div className="project">
                    <a href="https://www.datumlearn.com/" target="_blank" rel="noreferrer"><img src={mock07} className="zoom" alt="thumbnail" width="100%" /></a>
                    <a href="https://www.datumlearn.com/" target="_blank" rel="noreferrer"><h2>Datum: Integrated Learning Platform</h2></a>
                    <p>This is an online educational platform that provides high-quality, data science-focused learning resources in the Japanese language. I created the entire platform from scratch using Ruby on Rails.</p>
                </div>
                <div className="project">
                    <a href="http://www.wemanage.jp/" target="_blank" rel="noreferrer"><img src={mock06} className="zoom" alt="thumbnail" width="100%" /></a>
                    <a href="http://www.wemanage.jp/" target="_blank" rel="noreferrer"><h2>WeManage: Real Estate Asset Management</h2></a>
                    <p>This mobile application allows realtors in Japan to securely manage their property information and view future income predictions. This app is built with Ruby on Rails and JavaScript.</p>
                </div>
                <div className="project">
                    <a href="https://www.byuh.edu/covid-19-case-management" target="_blank" rel="noreferrer"><img src={mock05} className="zoom" alt="thumbnail" width="100%" /></a>
                    <a href="https://www.byuh.edu/covid-19-case-management" target="_blank" rel="noreferrer"><h2>COVID-19 Case Management</h2></a>
                    <p>Built official charts for COVID/vaccination tracking for an educational institution using JavaScript and the Google Sheets API v4. The dashboard served the university's leadership in their decision-making processes.</p>
                </div>
                <div className="project">
                    <a href="https://github.com/yujisatojr/multi-reg-analysis" target="_blank" rel="noreferrer"><img src={mock04} className="zoom" alt="thumbnail" width="100%" /></a>
                    <a href="https://github.com/yujisatojr/multi-reg-analysis" target="_blank" rel="noreferrer"><h2>Multiple Regression Property Analysis</h2></a>
                    <p>Analyzed the real estate market in Japan and predicted property prices by implementing statistical methods such as OLS and multi-regression analysis. This project leveraged Python and various libraries such as Pandas, NumPy, Matplotlib, and Scikit-Learn.</p>
                </div>
                <div className="project">
                    <a href="https://holokai.byuh.edu/programs-of-study" target="_blank" rel="noreferrer"><img src={mock03} className="zoom" alt="thumbnail" width="100%" /></a>
                    <a href="https://holokai.byuh.edu/programs-of-study" target="_blank" rel="noreferrer"><h2>Programs of Study</h2></a>
                    <p>Designed and developed a custom component for a CMS-based platform (e.g., 'Brightspot') using Java, Handlebars, and LESS. University students can find their majors of interest through this module.</p>
                </div>
                <div className="project">
                    <a href="https://hookele.byuh.edu/transfer-evaluation-guidelines-and-matrix" target="_blank" rel="noreferrer"><img src={mock02} className="zoom" alt="thumbnail" width="100%" /></a>
                    <a href="https://hookele.byuh.edu/transfer-evaluation-guidelines-and-matrix" target="_blank" rel="noreferrer"><h2>Transfer Evaluation Matrix</h2></a>
                    <p>Created an interactive CSV table generator with Java, Handlebars, and LESS. This project helps transfer students to quickly identify eligible credits.</p>
                </div>
                <div className="project">
                    <a href="https://github.com/yujisatojr/submeowrine" target="_blank" rel="noreferrer"><img src={mock01} className="zoom" alt="thumbnail" width="100%" /></a>
                    <a href="https://github.com/yujisatojr/submeowrine" target="_blank" rel="noreferrer"><h2>Submeowrine</h2></a>
                    <p>Developed and released an Android mobile application using Java and Android Studio that runs a 2D shooting game.</p>
                </div>
            </div>
        </div> */}
    </>
    );
}

export default Project;