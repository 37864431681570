import React, { useState, useEffect } from "react";
import {
    Main,
    Timeline,
    Expertise,
    Navigation,
    Footer,
    Project,
    Contact,
} from "./components";
import FadeIn from './components/FadeIn';
import './index.scss';
import Certificates from "./components/Certificates";

function App() {
    const [mode, setMode] = useState<string>('dark');

    const handleModeChange = () => {
        if (mode === 'dark') {
            setMode('light');
        } else {
            setMode('dark');
        }
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className={`main-container ${mode === 'dark' ? 'dark-mode' : 'light-mode'}`}>
            <Navigation parentToChild={{ mode }} modeChange={handleModeChange} />
            <FadeIn transitionDuration={700}>
                <Main />
                <Expertise />
                <Timeline />
                {/* <Certificates /> */}
                <Project />
                {/* <Contact /> */}
            </FadeIn>
            <Footer />
        </div>
    );
}

export default App;